import React, { useState } from 'react'
import Stake from './Stake'
import StakeList from './StakeList'
import Exchange from './Exchange'

function StakePage() {
    const [trigger, setTrigger] = useState(0)
    const [page, setPage] = useState(0)

    function updateTrigger() {
        setTrigger(trigger + 1)
    }
    return (
        <div className="w-full flex flex-col justify-center items-center">
            <div className="flex flex-row mt-10 w-[512px] h-[36px] drop-shadow-lg text-3xl text-white select-none overflow-hidden">
                <div
                    onClick={() => setPage(0)}
                    className={`w-1/3 ] hover:bg-[#ffd476] hover:text-[#ffaa00] cursor-pointer rounded-l-xl hover:text-4xl transition-all ${
                        page == 0
                            ? 'text-4xl text-[#ffaa00] bg-[#ffd476]'
                            : 'bg-[#ffaa00]'
                    }`}
                >
                    Mint!
                </div>
                <div
                    onClick={() => setPage(1)}
                    className={`w-1/3 ] hover:bg-[#ffd476] hover:text-[#ffaa00] cursor-pointer hover:text-4xl transition-all ${
                        page == 1
                            ? 'text-4xl text-[#ffaa00] bg-[#ffd476]'
                            : 'bg-[#ffaa00]'
                    }`}
                >
                    Stake!
                </div>
                <div className="w-1/3 bg-[#ffaa00] rounded-r-xl  transition-all">
                    NFT!
                </div>
            </div>

            {page == 0 ? (
                <Exchange />
            ) : page == 1 ? (
                <>
                    <Stake updateTrigger={updateTrigger} />
                    <StakeList trigger={trigger} />
                </>
            ) : null}
        </div>
    )
}

export default StakePage
